import React, {useState} from 'react';
import "./PromptSearch.css";

const PromptSearch = ({value, setValue, row, setVisible, filters ,setFilters}) => {
    const data = value;
    const [searchValue, setSearchValue] = useState("");

    function searchRows(){
        let newTable
        if(row==="countries"||row==="urban_areas"){
            if(searchValue[0]==="!")
                newTable = data.filter(el=>el[row].indexOf(searchValue.slice(1))===-1);
            else newTable = data.filter(el=>el[row].indexOf(searchValue)>=0);
        }
        else if(searchValue[0]===">"&&(row==="price"||row==="port"||row==="airport"||row==="volcano"||row==="mystery"||row==="crops"||row==="fruit"||row==="minerals"||row==="raw_meat"||row==="titanium"||row==="fish"||row==="iron"||row==="oil"||row==="stone"||row==="wood"||row==="urban_count")){
            newTable = data.filter(el=>+el[row]>+searchValue.slice(1))
        }
        else
            newTable = data.filter(el=>el[row]===searchValue);
        setFilters([...filters,row]);
        setValue(newTable);
        setVisible(false);
    }

    return (
        <div className={"Prompt"}>
            <div className={"Prompt-body"}>
                <h1>Search: {row}</h1>
                <input type='text' value={searchValue} onChange={e => setSearchValue(e.target.value)}/>
                <button onClick={searchRows} disabled={!searchValue.length}>Search</button>
                <button onClick={()=>setVisible(false)}>Cansel</button>
            </div>
        </div>
    );
};

export default PromptSearch;