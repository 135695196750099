import './App.css';
import React, {useEffect, useState} from "react";
import {useSortBy, useTable, usePagination, useExpanded} from "react-table";
import {MdSearch} from "react-icons/md";
import PromptSearch from "./components/PromptSearch/PromptSearch";
import axios from "axios";

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return <input type="checkbox" ref={resolvedRef} {...rest} />
    }
)

function Table({columns, data, skipPageReset, setSearchRow, setVisiblyPromptSearch}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        getToggleHideAllColumnsProps,
        allColumns,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageReset,
        },
        useSortBy,
        useExpanded,
        usePagination
    )
    useEffect(()=>setPageSize(5),[columns])
    return (
        <>
            <div className="columns-hidden-toggle">
                <div>
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
                    All
                </div>
                {allColumns.map(column => (
                    <div key={column.id}>
                        <label>
                            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                            {column.id}
                        </label>
                    </div>
                ))}
                <br/>
            </div>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th className="title" {...column.getHeaderProps(column.getSortByToggleProps())} style={{}}>
                                {column.render('Header')}
                                <span style={{marginRight: "5px"}}>
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                </span>
                                <span>
                                    <MdSearch size={15} onClick={(event) => {
                                        event.stopPropagation();
                                        setSearchRow(column.id);
                                        setVisiblyPromptSearch(true);
                                    }}/>
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td style={cell.value==="0"||cell.value==="null"? {color: "#333"}: {}} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                {' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                {' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                {' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>
                {' '}
                <span>
          Page{' '}
                    <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>

                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

function App() {
    const [defaultDataBase, setDefaultDataBase] = useState([]);
    const [visionDataBase, setVisionDataBase] = useState([]);
    const [visiblyPromptSearch, setVisiblyPromptSearch] = useState(false);
    const [searchRow, setSearchRow] = useState("");
    const [tableColumns, setTableColumns]=useState([]);
    const [currentFilters, setCurrentFilters] = useState([]);

    function generateColumn(keys){
        const new_columns = [];
        keys.map(el=>{
            new_columns.push({
                Header: el.replace("_"," "),
                accessor: el
            })
        })
        return new_columns;
    }

    useEffect(() => {
        axios.get("https://ertha.fun/api/")
        // axios.get("http://localhost")
            .then(res=>{
                if (typeof(res.data)==='object'){
                    const data = res.data;
                    setDefaultDataBase(JSON.parse(JSON.stringify(data)));
                    setVisionDataBase(JSON.parse(JSON.stringify(data)));
                    const columns = generateColumn(Object.keys(data[0]));
                    setTableColumns(columns)
                }
            })
            .catch(err=>{
                alert(err.response.data);
                console.log(err)
            })
    }, []);

    const columns = React.useMemo(
        ()=>tableColumns,
        [tableColumns]
    )
    const [skipPageReset, setSkipPageReset] = React.useState(false)


    React.useEffect(() => {
        setSkipPageReset(false)
    }, [visionDataBase])

    const resetData = () => {
        setCurrentFilters([]);
        setVisionDataBase(defaultDataBase);
    }
    return (
        <div className={"App"}>

            {visiblyPromptSearch ? <PromptSearch value={visionDataBase} setValue={setVisionDataBase} row={searchRow}
                                                 setVisible={setVisiblyPromptSearch} setFilters={setCurrentFilters} filters={currentFilters}/> : null}
            {
                tableColumns.length ?
                <><Table
                    columns={columns}
                    data={visionDataBase}
                    skipPageReset={skipPageReset}
                    setSearchRow={setSearchRow}
                    setVisiblyPromptSearch={setVisiblyPromptSearch}
                />
                    <div className={"App-filters-block"}>
                        <span>Applied searched filters:</span>
                        {currentFilters.length ? currentFilters.map(el=><span>{el}</span>):null}
                    </div>
                    <button onClick={resetData}>Reset Table</button></>
                    : <h1>Loading DataBase</h1>
            }
        </div>
    )
}

export default App;
